import React from 'react';
import './Footerbar.css';


const Footerbar= () => {
    return (
        <div className='footer'>
            <div className='ooo'>ООО МБС 2022г. Все права защищены. Сайт не является публичной офертой. Цены указаны БЕЗ НДС, стоимость за 1 погонный метр изделия. </div>
        </div>
    );
}

export default Footerbar;