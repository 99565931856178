import './Pages.css';
import {BrowserRouter} from "react-router-dom"
import AppRouter from './AppRouter'
import Navbar from '../components/ui/Navbar';
import Footerbar from '../components/ui/Footerbar';


function App() {
  return (
	<BrowserRouter>
		<Navbar />
        <AppRouter />
		<Footerbar />
	</BrowserRouter>
  );
}

export default App;
