import React, {useContext} from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import MainPage from './MainPage'
import AboutPage from './AboutPage'
import MaterialPage from './MaterialPage'
import PclientPage from './PclientPage'

const AppRouter= () => {

    return (
        <Routes>           
            <Route path="/"         element={<MainPage/>}       exact="true" />
            <Route path="/about"    element={<AboutPage/>}      exact="true" />
            <Route path="/material"    element={<MaterialPage/>}      exact="true" />
            <Route path="/pclient"    element={<PclientPage/>}      exact="true" />
            <Route path="*"         element={<Navigate to ="/" />}/> 
        </Routes>    
    );
};

export default AppRouter;