import './Pages.css';
import React, {useState, useEffect} from "react";
import {useFetching} from "../hooks/useFetching";
import Folder from '../components/ui/Folder';
import {csv_to_array} from '../services/csv_to_array.js'
import csvfile from "../data/data.csv";


const MainPage = () => {
    const [data, setData] = useState([]);

    const [fetchFile, isFileLoading, FileError] = useFetching(async () => {
        fetch(csvfile)
        .then((response) => response.text())
        .then((csvContent) => {
            setData(csv_to_array(csvContent,";"));
        });
    });

    useEffect(() => {
        fetchFile(csvfile)
    }, [csvfile]);

    return (
        <div className="mp_container">
            <div className="mp_container_info">Сайт не является публичной офертой. Цены указаны БЕЗ НДС, стоимость за 1 погонный метр изделия. Наличие материала указана в шт.</div>
            {data.map((element) => 
                <Folder name={element[0]} content={element[5]} root={true}/>
            )}
        </div>
    );
};

export default MainPage;