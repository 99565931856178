export function csv_to_array(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",");

    var objPattern = new RegExp(
        (
            // Delimiters.
            "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
            // Quoted fields.
            "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
            // Standard fields.
            "([^\"\\" + strDelimiter + "\\r\\n]*))"
        ),
        "gi"
    );

    var arrData = [[]];
    var arrMatches = null;

    while (arrMatches = objPattern.exec(strData)) {

        var strMatchedDelimiter = arrMatches[1];

        if (
            strMatchedDelimiter.length &&
            strMatchedDelimiter !== strDelimiter
        ) {
            arrData.push([]);
        }

        var strMatchedValue;

        if (arrMatches[2]) {
            strMatchedValue = arrMatches[2].replace(
                new RegExp("\"\"", "g"),
                "\""
            );
        } else {
            strMatchedValue = arrMatches[3];
        }

        arrData[arrData.length - 1].push(strMatchedValue);
    }

    //Cteate tree TBD: optimization
    var map = {}, node, roots = [], i;
  
    for (i = 0; i < arrData.length; i += 1) {
        map[arrData[i][1]] = i;
        arrData[i].push([]);
    }

    for (i = 0; i < arrData.length; i += 1) {
        if(arrData[i][0]=="")
            continue;
            
        node = arrData[i];
        if (node[2] !== "") {
            arrData[map[node[2]]][5].push(node);
        } else {
          roots.push(node);
        }
    }

    return (roots);
}
