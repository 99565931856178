import './Pages.css';
import React from "react";


const MaterialPage = () => {
    return (
        <div className="mp_container">
            <div className="mp_container_small">
                <h3>Ознакомится с видом материала в готовом помещении вы можете в наших видео:</h3>
                <div className="mp_container_small">
                    <iframe src="https://www.youtube.com/embed/VF2b29sUEuE?start=33" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe src="https://www.youtube.com/embed/7bpiXSJFdkc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <iframe src="https://www.youtube.com/embed/S52vawVa7o0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );
};

export default MaterialPage;