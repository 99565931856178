import './Pages.css';
import React from "react";
import pclient from './images/pclient.png'

const PclientPage = () => {
    return (
        <div className="mp_container">
            <div className="mp_container_small">
                <h3>Партнёрская программа</h3>
                <img className="images_w" src={pclient} alt="pclient"/>
            </div>
        </div>
    );
};

export default PclientPage;