import React, {useState} from 'react';
import { motion } from "framer-motion"
import './Folder.css';
import arrow_black from './images/arrow_black.svg'
import arrow_white from './images/arrow_white.svg'
import Product from './Product';

const Folder= ({name, content, root}) => {
    const [ShowFolder, setShowFolder] = useState(false);

    function fShowFolder(event) {
        event.preventDefault();
        setShowFolder(ShowFolder ? false : true);
        return;
    }

    return (
        <div className="folder">
            <motion.div layout whileTap={{ scale: 0.99 }}
                        className={ShowFolder ? "folder_info_open"
                                            : root ? "folder_info folder_info_root" : "folder_info folder_info_not_root"}
                        onClick={event=>fShowFolder(event)}>
                <div className="folder_name">{name}</div>
                <motion.img
                    animate={ShowFolder ? {rotate: 90} : {rotate: 0} }
                    className="folder_icon"
                    src={ShowFolder ? arrow_black : arrow_white}
                    alt={ShowFolder ? "open" : "close"}
                />
            </motion.div>
            {ShowFolder &&
                <div className="folder_container">
                    {content.map((item, i) => item[5][0] !== undefined || item[4] == "" ?
                        <Folder name={name + " > " + item[0]} content={item[5]}/>
                            :
                        <Product name={(i+1) + " " + item[0]} count={item[3]} cast={item[4]} root={false}/>
                    )}
                </div>
            }
        </div>
    );
}

export default Folder;