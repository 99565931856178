import React from 'react';
import { motion } from "framer-motion"
import './Product.css';


const Product= ({name, count, cast}) => {
    return (
        <motion.div
            style={{ y: -50 }} animate={{ y: 0 }}
            className="product"
        >
            <div className='product_name'>{name}</div>
            <div className="product_info">
                <div className="product_count">В наличии: {count < 0 ? "Уточняйте наличие" : count}</div>
                <div className="product_cast">Цена: {cast} руб.</div>
            </div>
        </motion.div>
    );
}

export default Product;