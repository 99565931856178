import React, {useRef, useState} from 'react';
import './Navbar.css';
import logo from './images/logo.png'
import wa from './images/wa.svg'
import tg from './images/tg.svg'
import ph from './images/phone.svg'
import pg from './images/page.svg'

const Navbar= () => {
    const [ShowMenu, setShowShowMenu] = useState(false);
    const refMenu = useRef(null);

    function handleClickOutside(event) {
        if (refMenu.current && !refMenu.current.contains(event.target)) {
            document.removeEventListener("mousedown", handleClickOutside);
            setShowShowMenu(false);
        }
    }

    function fShowShowMenu(event) {
        event.preventDefault();
        
        if(ShowMenu) {
            document.removeEventListener("mousedown", handleClickOutside);
            setShowShowMenu(false);
        } else {
            document.addEventListener("mousedown", handleClickOutside);
            setShowShowMenu(true);
        }
        return;
    }
    
    return (
        <div className="navbar">
            <div className="navbar_left">
                <a href={"https://termarioni-dealer.ru"}>
                    <img className="navbar_Logo" src={logo} alt="logo" />
                </a>
            </div>
            <div className="navbar_right">
                <div ref={refMenu} className={ShowMenu ? "dropdown_menu active" : "dropdown_menu"}>
                    <div className="dropdown_menu_toggle" onClick={event=>fShowShowMenu(event)}></div>
                    <div className="dropdown_menu_section">
                        <a href="tel:+79660342695" className="navbar_tel">
                            <img className="navbar_ph" src={ph} alt="phone"/>+7(966) 034-26-95
                        </a>
                        <a href={"https://wa.clck.bar/79660326255"} target={"_blank"} rel={"nofollow noopener noreferrer"}>
                            <img className="navbar_wa" src={wa} alt="whatsapp"/> WhatsApp 
                        </a>
                        <a href={"https://t.me/termarioni"} target={"_blank"} rel={"nofollow noopener noreferrer"}>
                            <img className="navbar_tg" src={tg} alt="telegram"/> Telegram 
                        </a>
                        <a href="/">
                            <img className="navbar_pg" src={pg} alt="items"/> Перечень товаров
                        </a>
                        <a href="/material">
                            <img className="navbar_pg" src={pg} alt="material"/> О материалах
                        </a>
                        <a href="/pclient">
                            <img className="navbar_pg" src={pg} alt="pclient"/> Партнёрская программа
                        </a>
                        <a href="/about">
                            <img className="navbar_pg" src={pg} alt="about"/> О нас
                        </a>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Navbar;