import './Pages.css';
import React from "react";


const AboutPage = () => {
    return (
        <div className="mp_container">
            <div className="mp_container_small">
                <h2><b>TERMARIONI</b> - является надёжным производителем и поставщиком термоольхи на рынке.</h2>
                <p>14 лет опыта, итальянское оборудование и европейские сертификаты качества позволяют нам создавать продукт премиум-класса, который становится частью интересных дизайн-концептов на Российском рынке.</p>

                <h3>Собственное столярное производство</h3>
                <p>Это помогает создавать нам высококачественные жаропрочные окна из дуба и двери из ряда видов древесин. Ручная работа наших мастеров над каждым экземпляром продукции позволяет создавать поистине произведения искусства, с которых начинается интерьер.</p>

                <h3>Контакты:</h3>
                <p>
                    <a href="tel:+79660342695" className="navbar_tel">
                        +7(966) 034-26-95
                    </a>
                </p>
                <p>
                    <a href="tel:+79685998257" className="navbar_tel">
                        +7(968) 599-82-57
                    </a>
                </p>
                <p>
                    <a href="https://zen.yandex.ru/termarioni" target={"_blank"} className="navbar_tel">
                        Yandex ZEN
                    </a>
                </p>
                <p>
                    <a href={"https://t.me/termarioni"} target={"_blank"} rel={"nofollow noopener noreferrer"} className="navbar_tel">
                        Telegram 
                    </a>
                </p>
                <p>
                    <a href="https://vk.com/termarioni" target={"_blank"} className="navbar_tel">
                        VK
                    </a>
                </p>
            </div>
        </div>
    );
};

export default AboutPage;